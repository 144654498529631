import { SettingOutlined } from '@ant-design/icons';
import { Card, Input, Select, Space, Button } from 'antd';
import {useState} from 'react';
const { Option } = Select;
const selectBefore = (
  <Select defaultValue="http://" className="select-before">
    <Option value="http://">http://</Option>
    <Option value="https://">https://</Option>
  </Select>
);
const selectAfter = (
  <Select defaultValue="/events" className="select-after">
    <Option value="/events">/events</Option>
  </Select>
);



const Contents = () => {
const [domain, setDomain] = useState('http://localhost:8080');
const [pixel, setPixel] = useState('443221930567828');

const onClick = () => {
  // window.fbq('consent', 'grant');
  window.fbq('track', 'ViewContent', {value:10, currency:'USD', content_type:'product', content_ids:"singleID"});
}

const onChange =(event) => {
    const input = event.target;
    if (!input.value?.length) {
      return;
    }
    if(input.id=='domain') {
      setDomain(input.value);
    }
    if(input.id=='pixel') {
      setPixel(input.value);
    }
  };

  return (
  <Space direction="horizontal">
  <Card title="Purchase" size="large">
      <Button type="primary" onClick = {onClick}>
        Purchase
    </Button>
    </Card>
  </Space>
  );
};

export default Contents;
